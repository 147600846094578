export function getPageNames() {
  return {
    'ml-dashboard': 'AUTH.MENU.DASHBOARD',
    'ml-todo': 'AUTH.MENU.TODO',
    'ml-errors': 'AUTH.MENU.ERROR',
    'ml-events': 'AUTH.MENU.EVENT',
    'ml-member-editor': 'AUTH.MENU.MEMBER_EDITOR',
    'ml-members-control': 'AUTH.MENU.MEMBERS_CONTROL',
    'ml-advanced-search': 'AUTH.MENU.ADV_SEARCH',
    'ml-import': 'AUTH.MENU.IMPORT',
    'ml-importcompanies': 'AUTH.MENU.IMPORTCOMPANIES',
    'ml-stickprov': 'AUTH.MENU.STICKPROV',
    'ml-ladok': 'AUTH.MENU.LADOK',
    'ml-properties': 'AUTH.MENU.ATTRIBUTES',
    'ml-member-manager': 'AUTH.MENU.RENEWS_DEPRECATED',
    'ml-renew-page': 'AUTH.MENU.RENEWS',
    'ml-mucf': 'AUTH.MENU.MUCF',
    'ml-settings': 'COMMON.SETTINGS',
    'ml-registersetup': 'AUTH.MENU.REGISTERSETUP',
    'ml-forms': 'AUTH.MENU.FORMS',
    'ml-periods': 'AUTH.MENU.PERIODS',
    'ml-page': 'AUTH.MENU.PAGE',
    'ml-spar': 'AUTH.MENU.SPAR',
    'ml-users': 'AUTH.MENU.USERS',
    'ml-subcompany': 'AUTH.MENU.SUBCOMPANY',
    'ml-kvitto': 'AUTH.MENU.KVITTO',
    'ml-files': 'AUTH.MENU.FILES',
    'ml-shopitem': 'AUTH.MENU.SHOPITEM',
    'ml-book': 'AUTH.MENU.BOOK',
    'ml-sendout': 'AUTH.MENU.SENDOUT',
    'ml-templates': 'AUTH.MENU.TEMPLATES',
    'ml-billing': 'AUTH.MENU.BILLING',
    'ml-companies': 'AUTH.MENU.COMPANIES',
    'ml-system': 'AUTH.MENU.SYSTEM',
    'ml-payments': 'AUTH.MENU.ORDERS',
    'ml-redoc': 'AUTH.MENU.REDOC',
    'ml-invoices': 'AUTH.MENU.INVOICES',
    'ml-fortnox-invoices': 'AUTH.MENU.FORTNOX_INVOICES',
    'ml-redeem-codes': 'AUTH.MENU.COUPONS',
    'ml-reports': 'AUTH.MENU.REPORTS',
    'ml-jobs': 'AUTH.MENU.JOBS',
    'ml-transactions': 'AUTH.MENU.TRANSACTIONS',
    'ml-education': 'AUTH.MENU.EDUCATION',
    'ml-apikeys': 'AUTH.MENU.APIKEYS',
    'ml-grant': 'AUTH.MENU.GRANT',
    'ml-sms': 'AUTH.MENU.SMS',
    'ml-duplicates': 'AUTH.MENU.DUPLICATES',
    'ml-faith': 'AUTH.MENU.FAITH',
    'ml-member-registrations': 'AUTH.MENU.MEMBER_REGISTRATIONS',
    'ml-history': 'AUTH.MENU.HISTORY',
    'ml-swish-merchant': 'AUTH.MENU.SWISH_MERCHANT',
    'ml-support-admin': 'AUTH.MENU.SUPPORT_ADMIN',
  }  
}
